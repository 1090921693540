import Credits from '../../components/credits/Credits';
import FAQs from '../../components/faqs/FAQs';
import Overview from '../../components/overview/Overview';
import styles from './AboutUs.module.scss';
import { SocialShareBtn,BreadCrumb } from '../../components/elements/Elements';
import MetaTags from '../../components/meta_tags/MetaTags';
import { Helmet } from 'react-helmet-async';
export default function AboutUs() {
const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"ABOUT US","url":"/about_us"}}

    return (
       <div className={styles.container}>
                       <Helmet>   
    {MetaTags("About us","The Free Speech Tracker website is a tool for monitoring and documenting instances of free speech violations across India. In an era where freedom of expression is increasingly under scrutiny and faces various challenges, this platform serves as a centralized repository of information for citizens, researchers, activists, and policymakers to defend our free speech.", "/images/featured_image.png", window.location.href)}
      </Helmet>
             <BreadCrumb
          breadcrumb={breadcrumb}
        />
         <div className={styles.about_us}>
         <div className={styles.case_study_title_panel}>
        Overview <SocialShareBtn />
        </div>
            <div id='overview'>
                <Overview />
            </div>
            <div id='faqs'>
                <FAQs />
            </div>
            <div id='credits'>
                <Credits />
            </div>
        </div>
       </div>
    );
}