import React,{useEffect,useState} from 'react';
import { feature_storied_data } from '../../temp/constant';
import styles from './Latest.module.scss';
import { getRquest } from '../../api';
import { slugify } from '../case_studies/CaseStudies';
import Loader from '../loader/Loader';

export default function Latest() {
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await getRquest('case-studies?populate=*&pagination[pageSize]=5');
            setApiData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className={styles.latest}>
            <div className={styles.heading}>Latest</div>
           {apiData? apiData.map((item) => <LatestCard key={item.id} data={item} />): <Loader/>
            }
        </div>
    )
}


function LatestCard({ data }) {
    return (
        <>
            <a href={`/${data.id}/${slugify(data.attributes.title)}`} className={'card ' + styles.story}>
                <img className={styles.image} src={data.attributes.featured_image.data != null ? "https://ahveeeez.sflc.in"+data.attributes.featured_image.data.attributes.url: "/images/case_studies/1.jpeg"} alt={data.attributes.title + " Image"} />
                <div style={{ width: "65%" }}>
                    <div className={styles.caption}>CASE STUDY</div>
                    <div className={styles.title}>{data.attributes.title}</div>
                    <div className={'subtitle ' + styles.description}>{data.description}</div>
                </div>
            </a>
        </>
    )
}