import { useMemo, useState } from "react";
import { case_studies_data, feature_storied_data } from "../../temp/constant";
import Styles from "./Stories.module.scss";
import { BreadCrumb, SocialShareBtn } from "../../components/elements/Elements";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";

const allFilters = [
  "See all",
  "Book Bans",
  "Restrictive Social Media Policies",
  "Website & App Bans",
  "Art Bans",
];

function StoryFilter(curFilter) {
  if (curFilter.curFilter == "See all") {
    return (
      <>
        <div className={Styles.item1 +" "+Styles.item}>
          <Card
            data={feature_storied_data[0]}
            style={{ flexDirection: "column" }}
          />
        </div>
        <div className={Styles.item2 +" "+Styles.item}>
          <Card data={feature_storied_data[1]} />
        </div>
        <div className={Styles.item3 +" "+Styles.item}>
          <Card data={feature_storied_data[2]} />
        </div>
        <div className={Styles.item4 +" "+Styles.item}>
          <Card data={feature_storied_data[3]} />
        </div>
      </>
    );
  }

  const filteredData = feature_storied_data.filter(
    (element) => element.caption == curFilter.curFilter
  );

  return (
    <>
      {filteredData.map((element, index) => (
        <div className={Styles.item4 + " "+ Styles.item} key={index}>
          <Card data={element} />
        </div>
      ))}
    </>
  );
}

export default function Stories() {
  const data = case_studies_data;

  const [curFilter, setFilters] = useState("See all");

  const remaining = useMemo(() => {
    const newarr = [];
    for (let i = 3; i < data.length; i++) newarr.push(data[i]);
    return newarr;
  }, [data, curFilter]);

  const breadcrumb = {
    page1: { name: "HOME", url: "/" },
    page2: { name: "STORIES", url: "/stories" },
  };
  return (
    <div className={Styles.outer_container}>
      <Helmet>
        {MetaTags(
          "Stories",
          "This page lists all the stories on this page. You may also use different filters.",
          "/images/featured_image.png",
          window.location.href
        )}
      </Helmet>
      <BreadCrumb breadcrumb={breadcrumb} />
      <div className={Styles.header}>
        <div className={Styles.heading}>Stories</div>
        <div className={Styles.filters_outer} style={{ display: "flex", gap: "10px" }}>
          {allFilters.map((item) => (
            <div
              key={item}
              onClick={() => setFilters(item)}
              className={Styles.filter}
              active={curFilter === item ? "true" : "false"}
            >
              {item}
            </div>
          ))}
        </div>
        <SocialShareBtn />
      </div>
      <div className={Styles.container}>
        <StoryFilter curFilter={curFilter} setFilters={setFilters} />
      </div>
      {remaining.map((item) => (
        <div className={Styles.item  }>
          <Card key={item.id} data={item} />
        </div>
      ))}
    </div>
  );
}

const Card = ({ data, style }) => {
  return (
    <a href={data.url} className={"card " + Styles.card} style={style}>
      <img className={Styles.image} src={data.image} alt={data.title} />
      <div className={Styles.content}>
        <div className={Styles.caption}>
          <div
            className={Styles.left_line}
            style={{ backgroundColor: data.caption_color }}
          />
          <div className="label">{data.caption}</div>
        </div>
        <div className={"title " + Styles.title}>{data.title}</div>
        <div className={"subtitle " + Styles.description}>
          {data.description}
        </div>
      </div>
    </a>
  );
};
