import React,{useState} from 'react';
import sflcinLogo from './sflcin_logo.png';
import styles from './Header.module.scss';

const CurrentDate = () => {
  const currentDate = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  return (
      <div>
          {formattedDate}
      </div>
  );
};

function Header( ) {
  const [showNav, setShowNav] = useState(false); 

  return (
          <div>
            <div className={styles.header_container}>
              <div className={styles.header_date}>
              <CurrentDate></CurrentDate>
              </div>
              <div className={styles.header_center_area}>
                <div className={styles.header_extra}>
                  <a className={styles.header_sflcinlogo} href="/"><img src={sflcinLogo} alt="sflcin logo"  /></a>
                  <div className={styles.current_date}> <CurrentDate></CurrentDate></div>
                 {showNav ?  <div className={styles.mobile_close_btn} onClick={()=>setShowNav(false)}><img src="./images/mob_close.png" alt="" /></div>:""}
                  </div>
                 
                <a  href="/" className={styles.header_title}>Free Speech Tracker</a>
                {/* <a  href="https://store.sflc.in" className={styles.header_donate_btn}>Donate</a> */}
              </div>
            
            <div className={styles.header_nav_outer}>
            
            <div className={`${styles.header_nav_bar+" "}${showNav ? ` ${styles.active}` : ''}`} >
              <div className={styles.nav_links + ' ' + styles.navbar_dropdown_container}>
                  <a href="/about_us" className={styles.nav_links+" "+styles.nav_link_dropdown}><span>About</span>
                  <div className={styles.nav_dropdown}>
                    <a href="/about_us#overview" className={styles.nav_links}>Overview</a>
                    <a href="/about_us#faqs" className={styles.nav_links}>FAQ's</a>
                    <a href="/about_us#credits" className={styles.nav_links}>Credit</a>
                  </div>
                  </a>
               
                </div>
                <div className={styles.nav_links + ' ' + styles.navbar_dropdown_container}>
                  <a href='/stories' className={styles.nav_links+" "+styles.nav_link_dropdown}><span>Stories</span>
                  <div className={styles.nav_dropdown}>
                    <a href="/404-website-not-found" className={styles.nav_links}>404: Website Not Found</a>
                    <a href="/lights_camera_banned" className={styles.nav_links}>Lights, Camera, Banned</a>
                    <a href="/reading_the_fine_print" className={styles.nav_links}>Reading the Fine Print</a>
                    <a href="/to-read-or-not" className={styles.nav_links}>To Read or Not</a>

                  </div>
                  </a>
               
                </div>
                <a href="/case_study" className={styles.nav_links}>Case Studies</a>
                <a href="/contact-us" className={styles.nav_links}>Contact Us</a>
                <a href="https://store.sflc.in" className={styles.nav_links + " "+styles.nav_donate}>Donate </a>
              </div>
              <div style={{display:"none"}} className={styles.header_mobile_nav}>
                <div onClick={() => setShowNav(!showNav)}  className={styles.nav_menu}><img src='./images/menu.png'/><span>Menu</span></div>
              <a href="https://store.sflc.in" className={`${styles.nav_links} ${styles.nav_donate}`}>DONATE</a>
              </div>
            </div>
            </div>
          </div>
  )
}

export default Header