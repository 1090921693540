import Styles from './InputDate.module.scss';

const InputDate = ({ data, setData, name, field_name }) => {
    return (
        <>
            <label className={Styles.question} htmlFor="date">{field_name|| "Date of Ban"}</label>
            <input className={Styles.input} placeholder='Select date' type="date" name={name || "date"} id="date" />
        </>
    )
}

export default InputDate;