import { overview_data } from '../../temp/constant';
import styles from './Overview.module.scss';
export default function Overview() {
    const data = overview_data;
    return (
        <>
            <div className={styles.description}>{data.description}</div>
            <div className={styles.feedback}>
                {data.feedback} <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
        </>
    )
}