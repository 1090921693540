import React from 'react';
import styles from './StoriesPage.module.scss';
import { BreadCrumb, BlogTags, SocialShareBtn } from '../Elements';
import headerImg from './header_img.svg';
import Arrow_img from './Arrow_img';
import DownloadImg from './DownloadImg';
import { ExcelDownloadWebsite,ExcelDownloadArts,ExcelDownloadBook,ExcelDownloadSocial } from '../../download/ExcelDownload';


export function StoriesPageHeader({img,title,sub_heading, tag, tag_bg, setReport,breadcrumb}) {
    return (
        <div className={styles.container}>
          <BreadCrumb breadcrumb={breadcrumb}/>
          <img className={styles.header_img} src={img || headerImg } alt="headerImage" />
          <div className={"slim_container "+styles.header}>
          <BlogTags bg={tag_bg} tag={tag || "Restrictive Social Media Policies"}/>
          <h1 className={styles.title}>{title || "Reading the Fine Print"}</h1>
          <h2 className={styles.sub_heading}>{sub_heading || "Exploring Social Media Restrictions and the Balance between Free Speech and Company Image"}</h2>
          <div className={styles.share_tab}>
            <button className={styles.report_ban_btn} onClick={setReport}>Report a Ban <Arrow_img/> </button>
            <SocialShareBtn/>
          </div>
          </div>
          </div>
      )
}

export function StoryBody({desc}){
  return (
    <div className={styles.description+" slim_container"}>{desc || "The Cinematograph Act of 1952 serves as the cornerstone of censorship in India, particularly within the realm of cinema, empowering the Central Board of Film Certification (CBFC) to approve films for theatrical release. However, debates surround the CBFC's role, with calls for reform to restrict its authority to certifying films for appropriate age groups rather than acting as a moral arbiter of public taste. The Ministry of Information and Broadcasting (I&B) has significantly influenced the landscape, especially in online censorship, raising concerns and sparking discussions about the regulation of digital content."}</div>
  )
}

export function StoryDownloadBtn({link,setReport,ban}){

  const downloadBtnMap = {
    "Website": <ExcelDownloadWebsite banName={ban} styles={styles.download_btn} />,
    "Book": <ExcelDownloadBook banName={ban} styles={styles.download_btn} />,
    "Art": <ExcelDownloadArts banName={ban} styles={styles.download_btn} />,
    "Restrictive Social Media Policies": <ExcelDownloadSocial banName={ban} styles={styles.download_btn} />,
  };
  const downloadBtn = downloadBtnMap[ban] || (
    <button>
      Download our dataset here <DownloadImg classname={styles.download_btn} />
    </button>
  );

  return(
    <div className="slim_container">
      <div className={styles.download_section}>
      {downloadBtn ? downloadBtn : <button>Download our dataset here <DownloadImg classname={styles.download_btn}/></button>}
      <button onClick={setReport}>Report any other {ban || ""} bans <Arrow_img arrowSvg={styles.download_arrow}  /></button>
    </div>
    </div>
  )
}