import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import BookPopup from '../../books/BookPopup';

const BookBansHorizontalCharts = ({apiResponse}) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [showBook, setShowBook] = useState(null);
  const reasonsArrayRef = useRef([]);
  const reasonInfoRef = useRef([]);
 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      renderMainChart(apiResponse);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    //fetch call for reasons information
    try {
      const response = await axios.get('https://ahveeeez.sflc.in/api/book-bans-reason-infos?populate=book_bans_reason'); // Replace with your API endpoint
      const apiResponse = response.data;
      console.log(apiResponse.data," book desc");
      reasonInfoRef.current= apiResponse.data;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const renderMainChart = (apiResponse) => {
    console.log(apiResponse,"apires")
    try {
      const reasons = [...new Set(apiResponse.data.map(book => {
        if (!book.attributes.reasons || !book.attributes.reasons.data[0]) {
          console.error('Missing reason data for book:', book);
          return undefined;
        }
        return book.attributes.reasons.data[0].attributes.Reason;
      }))].filter(Boolean);

      const laws = [...new Set(apiResponse.data.map(book => {
        if (!book.attributes.law_used || !book.attributes.law_used.data[0]) {
          console.error('Missing law_used data for book:', book);
          return undefined;
        }
        return book.attributes.law_used.data[0].attributes.law_name;
      }))].filter(Boolean);

      const colors = ["#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525","#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525","#2085D6","#44325E","#78A57099","#BD0101","#FFD450","#F29454","#252525"];
      
      let reasonsData = [];
      const seriesData = laws.map((law, index) => {
        return {
          name: law,
          data: reasons.map(reason => {
            const filteredBooks = apiResponse.data.filter(book =>
              book.attributes.reasons.data[0].attributes.Reason === reason &&
              book.attributes.law_used.data[0].attributes.law_name === law
            );
            if (filteredBooks.length !== 0) {
              reasonsData.push({
                reason: reason,
                law: law,
                data: filteredBooks
              });
            }

            return filteredBooks.length === 0 ? null : filteredBooks.length; 
          }),
          color: colors[index]
        };
      });

      setSeries(seriesData);
      reasonsArrayRef.current = reasonsData;
      console.log(reasonsData, "reasonsData after setting state");

      setOptions({
        chart: {
          fontFamily: "Epilogue",
          height: 'auto',
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: function(event, chartContext, config) {
              const reason = reasons[config.dataPointIndex];
              const law = seriesData[config.seriesIndex].name;
              console.log(reasonsArrayRef.current, "reasonsArrayRef in event");
              showBookClicked(reason, law);
            },
            dataPointMouseEnter: function(event) {
              event.target.style.cursor = "pointer";
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 10,
                style: {
                  fontSize: '13px',
                  fontWeight: 500,
                },
              },
            },
          },
        },
        xaxis: {
          categories: reasons,
          labels: {
            formatter: function (val) {
              return val + "K";
            }
          },
          title: {
            text: 'Law used',
            style: {
              cssClass: 'apexcharts-xaxis-label',
              fontWeight: 550,
              fontSize: 14
            },
            offsetY: -20
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          title: {
            text: 'Reasons',
            style: {
              fontWeight: 550,
              fontSize: 14
            }
          },
        },
        stroke: {
          width: 2,
          colors: ['var(--background)']
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 70,
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
            }
          }
        ]
      });
    } catch (error) {
      console.error('Error processing data:', error);
    }
  };

  const showBookClicked = (reason, law) => {
    const filteredBooks = reasonsArrayRef.current.filter(book =>
      book.reason === reason && book.law === law
    );
    const filteredInfo = reasonInfoRef.current.filter(info=>
      info.attributes.book_bans_reason.data.attributes.Reason == reason
    )
    setShowBook({
      books: filteredBooks,
      heading: reason,
      law:law,
      description: filteredInfo.length != 0 ? filteredInfo[0].attributes.desc:"", 
    });
    console.log(filteredInfo[0], "filtered books in showBookClicked");
  }; 

  return (
    <div id="chart" style={{ width: "100%" }}>
      <Chart options={options} series={series} width="100%" type="bar" height={"auto"} />
      {showBook && <BookPopup law={showBook.law} books={showBook.books[0].data} heading={showBook.heading} description={showBook.description} close={() => setShowBook(null)} />}
    </div>
  );
};

export default BookBansHorizontalCharts;
