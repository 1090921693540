import { credits_data } from "../../temp/constant";
import styles from "./Credits.module.scss";

export default function Credits() {
  const data = credits_data;
  return (
    <>
      <div className={styles.heading}>Credits</div>
      {/* {data.map((item) => <Card key={item.id} data={item} />)} */}
    <div className={styles.credit_outer}>
   <div className={styles.credits_detail}>
   The Free Speech Tracker was conceptualised and researched by Radhika Jhalani and Gyan Prakash Tripathi. It was designed and illustrated by Greeshma Chauhan, developed by Orendra Singh, and deployed by ⁨Kiran S Kunjumon⁩. External resource persons include Shafali Jain (Data collection & Research, Data visualisation, UX), Vishnu Prasad VS (UI/UX), Vinayak Aggrawal and Harsh Singh (contributors).
   </div> 
  </div>
    </>
  );
}

const Card = ({ data }) => {
  return (
    <>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.names}>{data.names}</div>
    </>
  );
};
