import React, { useState } from 'react';
import shareIcon from './share.svg';
import blogTagsStyle from './BlogTags.module.scss';
import shareStyles from './SocialShareBtn.module.scss';
import { CopyIcon, LinkIcon } from './SvgIcons/Icons';
import mastadonIco from "./mastadon.png";
import twitterIco from "./twitter.png";
import linkedinIco from "./linkedin.png";
import redditIco from "./reddit.png";
import facebookIco from "./facebook.png";


import breadCrumbStyles from './BreadCrumb.module.scss';

export function SocialShareBtn({icon}) {
  const [showShare, setShare] = useState(false);
  const [copy, setCopy] = useState("Copy Link");
  const shareUrl = window.location.href;
 
  const message = `Check out ${shareUrl} from the Free Speech Tracker by SFLC.in`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopy("Copied")
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };

  const showShareBox = () => {
    console.log(showShare)
    if(!showShare){
      setShare(true)
    }
    else{
      setShare(false)
    }
  }
  return (
    <div className={shareStyles.shareContainer} >
     <img className={shareStyles.social_share_icon} onClick={()=>showShareBox() } src={icon || shareIcon} alt="shareIcon"/>
     <div className={shareStyles.shareBtnContainer} onMouseLeave={()=>setShare(false)} style={showShare ? { display: "block" } : {}}>
      <p>Share Link</p>
      <div className={shareStyles.share_btn_row1}>
        <div className={shareStyles.share_r1_btn1}><LinkIcon yourClass={shareStyles.link_btn}/><input type="text" disabled value={shareUrl} /></div>
        <div className={shareStyles.copy_link_btn} onClick={()=>{copyToClipboard()}}>{copy} <CopyIcon yourClass={shareStyles.copy_btn}/></div>
      </div>
      <div className={shareStyles.share_icons}>
        <a href={`https://mastodon.social/share?text=${encodeURIComponent(message)}`} target='_blank'><img src={mastadonIco} alt="mastadon Icon" /></a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} target='_blank'><img src={facebookIco} alt="facebook Icon" /></a>
        <a href={ `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`} target='_blank'><img src={twitterIco} alt="twitter Icon" /></a>
        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(message)}`} target='_blank'><img src={linkedinIco} alt="Linked Icon" /></a>
      </div>
     </div>
    </div>
  )
}

export function BlogTags({tag,bg}){
  const color ={"red":"#D96A6A","orange":"#F29454","green":"#78A57099","cyan":"#82D4E6"}
    return(
      <div className={blogTagsStyle.caption}>
      <div className={blogTagsStyle.left_line} style={{ backgroundColor: color[bg] || color.red }} />
      <div className='label'>
          {tag || "Restrictive Policy"}
      </div>
  </div>
    )
}

export function BreadCrumb({ theme, breadcrumb}){
  return(
      <div className={"bread_crumbs " + breadCrumbStyles.bread_crumbs}>
                    <a href={breadcrumb.page1.url||"/"} className={"bread_crumbs_pre "+theme}>{breadcrumb.page1.name||"HOME"}</a>
                    <a href={breadcrumb.page2.url ? breadcrumb.page2.url : undefined} className={"bread_crumbs_cur "+theme}>{breadcrumb.page2.name||""}</a>
                </div>
  )
}
