import styles from './Card.module.scss'

export default function Card({ data }) {
    return (
        <div style={{cursor:"pointer"}} onClick={()=>{window.location.href=`${data.url}`}} className={"card " + styles.card}>
            <img className={styles.image} src={data.image} alt={data.title} />
            <div className={styles.caption}>
                <div className={styles.left_line} style={{ backgroundColor: data.caption_color }}/>
                <div className='label'>
                    {data.caption}
                </div>
            </div> 
            <div className={'title ' + styles.title}>{data.title}</div>
            <div className={'subtitle ' + styles.description}>{data.description}</div>
        </div>
    )
}