import React,{useState} from "react";
import facebookLogo from "./icons/facebook.svg";
import twitterLogo from "./icons/twitter.svg";
import instaLogo from "./icons/insta.svg";
import mastadonLogo from "./icons/mastadon.svg";
import linkedinLogo from "./icons/linkedin.svg";
import cclogo from "./icons/cc.png";
import styles from './Footer.module.scss'; // Import SCSS module
import { postBan } from "../../api";

function Footer() {

  return (
    <div>
      <div className={styles.footer_container}>
        <div className={styles.footer_inner}>
          <div className={styles.footer_col}>
            <h4 className={styles.footer_title}>About us</h4>
            <div className={styles.footer_body}>
              SFLC.IN is a donor supported legal services organization that
              brings together lawyers, policy analysts, students, and
              technologists to protect freedom in the digital world. SFLC.IN
              promotes innovation and open access to knowledge by helping
              developers make great Free and Open Source Software, protect
              privacy and civil liberties for citizens in the digital world by
              educating and providing free legal advice and help policy makers
              make informed and just decisions with the use and adoption of
              technology.
            </div>
            <div className={styles.footer_body}>
              In special Consultative Status with the Economic and Social
              Council of the United Nations (ECOSOC).
            </div>
            <div className={styles.footer_social_media}>
              <a href="https://facebook.com/sflcin" className={styles.footer_social_lnks}>
                <img src={facebookLogo} alt="Facebook handle for sflcin" />
              </a>
              <a href="https://twitter.com/sflcin" className={styles.footer_social_lnks}>
                <img src={twitterLogo} alt="Twitter handle for sflcin" />
              </a>
              <a href="https://www.linkedin.com/company/software-freedom-law-centre-india-sflc.in-/?originalSubdomain=in" className={styles.footer_social_lnks}>
                <img src={linkedinLogo} alt="Linkedin handle for sflcin" />
              </a>
              <a href="https://mastodon.social/@sflcin" className={styles.footer_social_lnks}>
                <img src={mastadonLogo} alt="Mastadon handle for sflcin" />
              </a>
              <a href="https://www.instagram.com/sflc_in/" className={styles.footer_social_lnks}>
                <img src={instaLogo} alt="Instagram handle for sflcin" />
              </a>
            </div>
          </div>
          <div className={styles.footer_col}>
            <h4 className={styles.footer_title}>Sitemap</h4>
            <div className={styles.footer_links_outer}>
              <a href="/" className={styles.footer_links}>
                Home
              </a>
              <a href="/about_us" className={styles.footer_links}>
                About
              </a>
              <a href="/stories" className={styles.footer_links}>
                Stories
              </a>
              <a href="/case_study" className={styles.footer_links}>
                Case Studies
              </a>
              <a href="/contact-us" className={styles.footer_links}>
                Contact Us
              </a>
              <a href="https://store.sflc.in" className={styles.footer_links}>
                Donate
              </a>
            </div>
          </div>
          <div className={styles.footer_col}>
         
            <h4 className={styles.footer_title}>Contact us</h4>
            <div className={styles.footer_body}>
              <p className={styles.footer_body_p}>
                Software Freedom Law Center,
                India K-9, 2nd Floor,
                Birbal Road, Jangpura Extension, 
                New Delhi, India - 110014 <br />
              </p>
              <p className={styles.footer_body_p}>
                Phone: <a href="tel:+91-11-43587126">+91-11-43587126</a>
              </p>
              <p className={styles.footer_body_p}>
                Email: <a href="mailto:mail@sflc.in">mail@sflc.in</a> | <a href="mailto:press@sflc.in">press@sflc.in</a>
              </p>
              <p className={styles.footer_body_p}>
                
              </p>
              <div className={styles.footer_newsletter}>
              <div className={styles.footer_newsltr_title}>
                SUBSCRIBE TO OUR NEWSLETTER
              </div>
              <div className={styles.footer_newsltr_body}>
               To remain abreast of all things policy, digital rights, and our work.<br/>Click to go on our newsletter platform and subscribe.
              </div>
              <form id="footer_newsltr" className={styles.footer_newsltr_form}>
                {/* <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                  className={styles.footer_newsltr_mail}
                /> */}
                <div className={styles.footer_newsltr_subscribe_btn} onClick={()=>{window.location.href="https://newslettersflcin.hosted.phplist.com/lists/?p=subscribe&id=1"}}>SUBSCRIBE</div>
              </form>
            </div>
              <p className={styles.footer_img}><img src={cclogo} alt="" /></p>
            </div>
            
          </div>
        </div>
        <div className={styles.footer_bottom}>
            <div className={styles.footer_bottom_links}>
                <a href="https://sflc.in/privacy-policy/" target="_blank" className={styles.footer_links}>Privacy Policy</a>
                <a href="https://sflc.in/terms-and-conditions/" target="_blank" className={styles.footer_links}>Terms & Conditions</a>
                <a href="https://sflc.in/refund-policy-2/" target="_blank" className={styles.footer_links}>Refund Policy</a>
            </div>
            <div className={styles.footer_bottom_text}>
                <a href="https://SFLC.IN">SFLC.IN</a> &#169; 2024 Software Freedom Law Center, India
            </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
