import Styles from './InputRadio.module.scss';

const InputRadio = ({ selected, setSelected, options, title, name }) => {

    const onSelect = (e) => {
        if(setSelected)
        setSelected(e.currentTarget.value);
    }

    return (
        <>
            <legend className={Styles.title}>{title}</legend>
            {
                options.map((item) => (
                    <div  key={item}>
                        <input name={name} className={Styles.radio} type="radio" id={item} value={item} onChange={onSelect} checked={selected === item} />
                        <label className={Styles.option} for={item}>{item}</label>
                    </div>
                ))
            }
        </>
    )
}

export default InputRadio;