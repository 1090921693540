import { useState } from 'react';
import Styles from './QuizPage.module.scss';
import { quiz_data } from '../../temp/constant';
import QuizNumber from '../../components/quiz/QuizNumber';
import QuizOptions from '../../components/quiz/QuizOptions.jsx';

const QuizPage = () => {

    const data = quiz_data;
    const [quizNumber, setQuizNumber] = useState(0);

    const next = () => {
        setQuizNumber(quizNumber + 1);
    }
    if(quizNumber == data.length){
        return("Thank you")
    }
    return (
        <div className={Styles.container}>
            {
                data[quizNumber].type === 'number' && <QuizNumber key={quizNumber}  next={next} data={data[quizNumber]} />
            }
            {
                data[quizNumber].type === 'options' && <QuizOptions key={quizNumber} next={next} data={data[quizNumber]} />
            }
        </div>
    )
}

export default QuizPage;