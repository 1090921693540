import React from "react";
import { Tooltip } from "react-tooltip";
import styles from "./ReadingThePrint.module.scss";
import {
  StoriesPageHeader,
  StoryBody,
  StoryDownloadBtn,
} from "../../components/elements/stories_page/StoriesPage";
import LineChart2 from "../../components/elements/charts/LineChart2";
import SankeyChart from "../../components/elements/charts/SankyChart";
import HorizontalChart from "../../components/elements/charts/HorizontalChart";
import { Helmet } from "react-helmet-async";
import MetaTags from "../../components/meta_tags/MetaTags";


function ReadingTheFinePrint({setReport}) {
  const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"STORIES","url":"/stories"}}
  return (

    <div className={styles.container}>
                                <Helmet>   
    {MetaTags("Reading the Fine Print","Exploring social media restrictions and the balance between free speech and company image.", "./images/featured_stories/1.png", window.location.href)}
      </Helmet>
      <StoriesPageHeader 
        tag_bg={"red"}
        title={"Reading the Fine Print"}
        sub_heading={
          "Exploring social media restrictions and the balance between free speech and company image."
        }
        tag={"Restrictive Social Media Policies"}
        img={"./images/featured_stories/1.png"}
        breadcrumb={breadcrumb}
        setReport={setReport}
      />
      <StoryBody
        desc={
          "The relationship between employees and employers has become increasingly complex, particularly when it comes to the use of social media platforms. While Article 19 of the Indian Constitution provides freedom of speech and expression, it is only applicable against the state, leaving private entities to establish their own policies regarding social media use. As a result, many companies in India have implemented restrictive social media policies that limit their employees' ability to express themselves online - be it personal views or criticism against them."
        }
      />
      <div>
        <div className={styles.spectrum}>
          <p>
          Companies across spectrum have <br/> restrictive policies for their employees 
            {/* Companies Across Spectrum have <br></br>restrictive policies */}
          </p>

          <img src="./images/featured_stories/wordcloud.png" alt="" />
        </div>

        <div className={styles.post_kind}>
          <p>
          Kinds of posts people have been fired over
          </p>

          <div className={styles.post_kind_word_cloud}>
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-religious"
              clickable
            />
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-recession"
              clickable
            />
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-fake"
              clickable
            />
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-rape"
              clickable
            />
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-political"
              clickable
            />
            <Tooltip
              className={styles.tooltip_class}
              id="my-tooltip-wage"
              clickable
            />
            <p>
              Religious{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-religious"
                data-tooltip-content={`Hindustan Times suspended one of its journalists, Srishti Jaswal, after Jaswal was accused of posting a "derogatory" tweet about the god Krishna.`}
              />
            </p>
            <p>
              Recession{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-recession"
                data-tooltip-content="A software engineer was fired after tweeting about tech industry recession and expressing career concerns."
              />
            </p>
            <p>
              Fake news{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-fake"
                data-tooltip-content={`Angshukanta Chakraborty was fired from her position as political editor of DailyO, which is owned by the India Today Group for calling out “promoters” who turn a “blind eye” to journalists spreading hate or fake news.`}
              />
            </p>
            <p>
              Rape{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-rape"
                data-tooltip-content={`The assistant manager with the Kochi branch of Kotak Mahindra Bank had commented on social media justifying the gangrape and murder of an eight-year-old girl in Jammu and Kashmir’s Kathua.`}
              />
            </p>
            <p>
              Political{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-political"
                data-tooltip-content={`Aaj Tak terminated Shyaam Meera Singh who questioned PM Modi in his tweets. India today group cited their policies on social media and code of conduct "which specifically advise to use social media for news that has been published or aired by the system and not for personal views" as their reason for termination.`}
              />
            </p>
            <p>
              Wage Settlement{" "}
              <img

                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-wage"
                data-tooltip-content={
                  "Hitachi claimed the employee had put the two posts on his Facebook account during a wage settlement dispute. They claimed the posts were defamatory and instigated its employees against the company management."
                }
              />
            </p>
          </div>
        </div>
        <StoryBody
          desc={`While social media can serve as a tool for holding individuals accountable for discriminatory behavior, it also raises concerns about right to privacy, HR practices, and the blurred lines between personal expression and professional repercussions. The "hidden curriculum of surveillance" that emerges when employees feel monitored on social media can stifle authentic expression and create a culture of self-censorship. This dynamic especially places journalists in challenging positions where they must navigate between professional obligations and personal freedoms, often facing pressure from online trolls, their employers and governmental scrutiny. In an ideal scenario, what measures should be in place to safeguard both employee privacy and freedom of expression while maintaining accountability and professionalism?`}
        />
        <div>
          <StoryDownloadBtn setReport={setReport} ban={"Restrictive Social Media Policies"} />
        </div>
      </div>
    </div>
  );
}

export default ReadingTheFinePrint;
