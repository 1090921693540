import styles from './FeaturedStories.module.scss'
import { feature_storied_data as data } from '../../temp/constant'
import Card from './Card/Card'
export default function FeaturedStories() {
    return (
        <div className={styles.featured_stories}>
            <div className={styles.heading}>Featured Stories <div onClick={()=>{window.location.href='/stories'}}>VIEW ALL</div></div>
            <div className={styles.containers}>
                {data.map((item) => <Card key={item.id} data={item} />)}
            </div>
        </div>
    )
}