
import { useState } from 'react';
import Styles from './QuizNumber.module.scss';


const StateEnum = {
    NOT_ANSWERED: 0,
    CORRECT_ANSWERED: 1,
    WRONG_ANSWERED: 2,
};
const QuizNumber = ({ next, data }) => {

    const [state, setState] = useState(StateEnum.NOT_ANSWERED);

    const [inputValue, setInputValue] = useState(0);

    const onClick = () => {
        if (StateEnum.NOT_ANSWERED !== state) {
            next();
            return;
        }

        if (inputValue == data.correct_answer.value) {
            setState(StateEnum.CORRECT_ANSWERED);
        } else {
            setState(StateEnum.WRONG_ANSWERED);
        }
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.question}>{data.question}</div>
            <div className={Styles.instructions}>{data.instructions}</div>
            {
                state === StateEnum.NOT_ANSWERED &&
              <>
                <input type='range' className={Styles.not_answered + " "+Styles.input_range}  value={inputValue ?? 0} onChange={(e) => setInputValue(e.target.value)}></input>

                <div className={Styles.current_value}>{inputValue}</div>
            
              </>
            }
            {
                state === StateEnum.CORRECT_ANSWERED &&
                <>
                    <input type='range' disabled={true} className={Styles.input_range+" "+Styles.input_range_correct} value={inputValue} ></input>
                    <div><span>You guessed {inputValue} Correct Answer {data.correct_answer.value}</span></div>
                    <div className={Styles.text}>{data.correct_answer.text} </div>

                    {data.correct_answer.image && <img className={Styles.image} src={data.correct_answer.image} alt="corrent answer" />}
                </>
            }
            {
                state === StateEnum.WRONG_ANSWERED &&
                <>
                    <input type='range' disabled={true} className={ Styles.input_range + " "+Styles.input_range_correct} value={data.correct_answer.value} ></input>

                    <input type='range' disabled={true} className={Styles.input_range + " "+ Styles.input_range_wrong} value={inputValue}></input>

                    {/* <input type='range' disabled={true} className={Styles.correct_answer} value={data.correct_answer.value}></input> */}
                    <div className={Styles.current_value} >
                        <span>You guessed {inputValue}</span>
                         <span>Correct Answer {data.correct_answer.value}</span></div>
                    <div className={Styles.text}>{data.wrong_answer.text}</div>
                    {data.wrong_answer.image && <img className={Styles.image} src={data.wrong_answer.image} alt="wrong answer" />}
                </>
            }
            <button className={Styles.button} onClick={onClick} disabled={inputValue == 0}>{state === StateEnum.NOT_ANSWERED ? "Start" : "Next"}</button>
        </div>
    );
};

export default QuizNumber;