import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import axios from 'axios';

const CustomDot = (props) => {
  const { cx, cy, payload } = props;

  if (!payload.year) {
    return null; // Return null for the extra entry with no data
  }

  return (
    <image
      href={payload.bookCoverUrl}
      x={cx - 15}
      y={cy - 20}
      width={30}
      height={40}
      style={{ pointerEvents: 'none' }}
    />
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const book = payload[0].payload;
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px',width:300 }}>
        <img src={book.bookCoverUrl} alt={book.title} style={{ width: '50px', height: 'auto' }} />
        <p>{book.title}</p>
        <p>{`Year: ${book.year}`}</p>
        <p>{book.description}</p>
      </div>
    );
  }
  return null;
};

const BooksChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from API
    axios.get('https://ahveeeez.sflc.in/api/book-bans?populate=book_cover,reasons,year')
      .then(response => {
        const booksData = response.data.data.map(book => {
          const attributes = book.attributes || {};
          const bookCoverData = attributes.book_cover?.data?.attributes?.formats?.thumbnail || {};
          const reasonsData = attributes.reasons?.data || [];

          return {
            id: book.id,
            year: attributes.year || null,
            title: attributes.book_title || 'Unknown Title',
            description: attributes.description || 'No description available.',
            bookCoverUrl: bookCoverData.url ? "https://ahveeeez.sflc.in" + bookCoverData.url : 'https://ahveeeez.sflc.in/uploads/Unavailable_Book_Cover_FST_1fdd475882.png',
            reason: reasonsData.length > 0 ? reasonsData[0].attributes.Reason : 'Unknown'
          };
        });

        // Sort booksData in descending order based on year
        // booksData.sort((a, b) => b.year - a.year);

        // Add an extra entry with no data at origin (year 0) for "Reasons"
        const emptySpaceEntry = {
          year: 0,
          title: "Reasons",
          bookCoverUrl: '',
          reason:""
        };

        // Insert emptySpaceEntry at the beginning of booksData
        booksData.unshift(emptySpaceEntry);

        setData(booksData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Create a unique set of reasons
  const uniqueReasons = [...new Set(data.map(book => book.reason))];

  // Create a mapping of reasons to Y-axis positions
  const reasonToYAxisPosition = {};
  uniqueReasons.forEach((reason, index) => {
    reasonToYAxisPosition[reason] = index + 1; // Start Y-axis from 1 to avoid overlapping with X-axis
  });

  // Transform data to include Y-axis positions
  const transformedData = data.map(book => ({
    ...book,
    yAxisPosition: reasonToYAxisPosition[book.reason]
  }));

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart data={transformedData} margin={{ top: 50, right: 50, left: 50, bottom: 50 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis
          type="number"
          tickCount={uniqueReasons.length} // Ensure all reasons are displayed on Y-axis
          domain={[1, uniqueReasons.length]} // Adjust domain to show all reasons
          tickFormatter={index => uniqueReasons[index - 1]} // Map Y-axis positions to reasons
          width={120} // Increase width if Y-axis labels are still cut off
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="yAxisPosition"
          stroke="var(--background)" // Customize line color here
          dot={<CustomDot />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BooksChart;


