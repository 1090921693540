import { useState } from 'react';
import Styles from './QuizOptions.module.scss';

const StateEnum = {
    NOT_ANSWERED: 0,
    CORRECT_ANSWERED: 1,
    WRONG_ANSWERED: 2,
};

const QuizOptions = ({ next, data }) => {

    const [state, setState] = useState(StateEnum.NOT_ANSWERED);

    const [inputValue, setInputValue] = useState(null);

    const onClick = () => {
        if (StateEnum.NOT_ANSWERED !== state) {
            next();
            return;
        }

        if (inputValue == data.correct_answer.value) {
            setState(StateEnum.CORRECT_ANSWERED);
        } else {
            setState(StateEnum.WRONG_ANSWERED);
        }
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.question}>{data.question}</div>
            <div className={Styles.instructions}>{data.instructions}</div>
            <Option options={data.options} inputValue={inputValue} setInputValue={setInputValue} state={state} correct_value={data.correct_answer.value} />
            {
                state === StateEnum.CORRECT_ANSWERED &&
                <>
                    <div className={Styles.text}>{data.correct_answer.text}</div>
                    {data.correct_answer.image && <img className={Styles.image} src={data.correct_answer.image} alt="corrent answer" />}
                </>
            }
            {
                state === StateEnum.WRONG_ANSWERED &&
                <>
                    <div className={Styles.text}>{data.wrong_answer.text}</div>
                    {data.wrong_answer.image && <img className={Styles.image} src={data.wrong_answer.image} alt="wrong answer" />}
                </>
            }
            <button className={Styles.button} onClick={onClick} disabled={inputValue == null}>{state === StateEnum.NOT_ANSWERED ? "Start" : "Next"}</button>
        </div>
    );
};


const Option = ({ options, inputValue, setInputValue, state, correct_value }) => {

    const getOptionsClassName = (index) => {
        if (state === StateEnum.NOT_ANSWERED) {
            if (index + 1 === inputValue) return Styles.selected;
        } else {
            if (index + 1 === correct_value) return Styles.selected_corrent;
            else if (index + 1 !== correct_value && index + 1 === inputValue) return Styles.selected_wrong;
        }
        return null;
    }
    return options.map((item, index) =>
        <div key={index} text={item} style={{
            cursor: state === StateEnum.NOT_ANSWERED ? "pointer" : null
        }} onClick={() => setInputValue(index + 1)} className={Styles.options_default + " " + getOptionsClassName(index)} >{options[index]}</div>)

}
export default QuizOptions;