import React from 'react';
import styles from './StoriesPage.module.scss';

function DownloadImg({classname}) {
  return (
<>
<svg className={classname} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 14.6207L4.16669 8.78739L5.80002 7.09573L8.83335 10.1291V0.620728H11.1667V10.1291L14.2 7.09573L15.8334 8.78739L10 14.6207ZM3.00002 19.2874C2.35835 19.2874 1.80905 19.0589 1.3521 18.602C0.895159 18.145 0.666687 17.5957 0.666687 16.9541V13.4541H3.00002V16.9541H17V13.4541H19.3334V16.9541C19.3334 17.5957 19.1049 18.145 18.6479 18.602C18.191 19.0589 17.6417 19.2874 17 19.2874H3.00002Z"/>
</svg>
</>
  )
}

export default DownloadImg
