import React from 'react';
import styles from './StoriesPage.module.scss';

function Arrow_img({arrowSvg}) {
  return (
<>
<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={arrowSvg || styles.arrowSvg} d="M1.4 13.9153L0 12.5153L9.6 2.91528H1V0.915283H13V12.9153H11V4.31528L1.4 13.9153Z"/>
    </svg>
</>
  )
}

export default Arrow_img
