import React from "react";
import { BreadCrumb, SocialShareBtn } from "../../components/elements/Elements";
import styles from "./ContactUs.module.scss";
import { FooterSocial } from "../../components/Footer/Footer";
import facebookLogo from "./icons/facebook.svg";
import twitterLogo from "./icons/twitter.svg";
import instaLogo from "./icons/insta.svg";
import mastadonLogo from "./icons/mastadon.svg";
import linkedinLogo from "./icons/linkedin.svg";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";
function ContactUs() {
  const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"CONTACT US","url":"/contact-us"}}
  return (
    
    <div>
      <Helmet>   
    {MetaTags("Contact Us","The Free Speech Tracker attempts to collect data on free speech violations happening across India. It owes its success to its collaborative process of data collection and verification.", "/images/featured_image.png", window.location.href)}
      </Helmet>
      <div className={styles.container}>
        <BreadCrumb breadcrumb={breadcrumb} />
        <div className={styles.contact_heading}>
          Contact us<SocialShareBtn></SocialShareBtn>
        </div>
        <div className={styles.cont_desc}>
        The Free Speech Tracker attempts to collect data on free speech
          violations happening across India. It owes its success to its
          collaborative process of data collection and verification. We rely on
          your engagement to help us ensure data accuracy. Whether you have
          experienced a banned website, a student, a journalist, a lawyer, or a
          public-spirited person with interest in the topic, we welcome you to
          participate in improving our work.
        </div>
        <div className={styles.second_secion}>
          <div className={styles.submit_data}>
            <h2>Submit Data</h2>
            <ul>
              <li>
                Have you encountered a ban that is not reported in our database?{" "}
              </li>
              <li>
                Did you find a document that may help us improve our work?{" "}
              </li>
              <li>
                Are you a journalist who recently wrote an article you would
                like us to add to our coverage?{" "}
              </li>
            </ul>
            <p>
              Please submit your data through our online means or a phone number
              or a text message.
            </p>
            <p>
                Phone: <a href="tel:+91-11-43587126">+91-11-43587126</a>
              </p>
              <p>
                Email: <a href="mailto:mail@sflc.in">mail@sflc.in</a>
              </p>
              <p>
                Email: <a href="mailto:press@sflc.in">press@sflc.in</a>
              </p>
          </div>
          <div className={styles.submit_data}>
            <h2>Volunteer With us</h2>
            <p>
            We are always looking for volunteers to help. Whether you have an hour or a month or a year, don't underestimate your power to affect change. No matter where in the world you are, you can assist us. Please sign up with us by writing to <a href="mailto: mail@sflc.in"> mail@sflc.in</a>
            </p>
          </div>
        </div>
        <div className={styles.third_section}>
          <h2>Take Action</h2>
          <p>
            Has our work inspired you to advocate for social change and defend
            digital freedoms? SFLC.in's "Take Action" section has options like
            Petitions, Letters, Suggest an RTI and Give inputs on Strategic
            Litigation. Please use them.
          </p>
          <a href="https://sflc.in">Go to SFLC.in </a>
        </div>
        <div className={styles.forth_section}>
          <h2>Spread the message</h2>
          <p>
            The chances of this issue being resolved are only as high as the
            number of people that are talking about this issue. You can help us
            spread the message by posting on social media, and by talking to
            people about this website and the data on this website.
          </p>
          <div className={styles.footer_social_media}>
              <a href="https://facebook.com/sflcin" className={styles.footer_social_lnks}>
                <img src={facebookLogo} alt="Facebook handle for sflcin" />
              </a>
              <a href="https://twitter.com/sflcin" className={styles.footer_social_lnks}>
                <img src={twitterLogo} alt="Twitter handle for sflcin" />
              </a>
              <a href="https://www.linkedin.com/company/software-freedom-law-centre-india-sflc.in-/?originalSubdomain=in" className={styles.footer_social_lnks}>
                <img src={linkedinLogo} alt="Linkedin handle for sflcin" />
              </a>
              <a href="https://mastodon.social/@sflcin" className={styles.footer_social_lnks}>
                <img src={mastadonLogo} alt="Mastadon handle for sflcin" />
              </a>
              <a href="https://www.instagram.com/sflc_in/" className={styles.footer_social_lnks}>
                <img src={instaLogo} alt="Instagram handle for sflcin" />
              </a>
            </div>
        </div>
        <h1 className={styles.improve_the_website}>Improve this website</h1>
        <h2 className={styles.improve_the_website_sub}>
          You can contribute to the user experience on this website in many
          ways.
        </h2>

        <div className={styles.fifth_section}>
          <div className={styles.col_1}>
            <h2>Report bugs</h2>
            <p>
              If you face any issues in using this website or if you find any
              bugs in this website, please feel free to file a bug report at
              GitLab. Please help us in keeping down duplicate bug reports by
              ensuring that the bug you are reporting has not been reported
              before.
            </p>
            <a href="">Report a bug</a>
          </div>
          <div className={styles.col_1}>
            <h2>Contribute code</h2>
            <p>
            Alternatively, you can help us in improving the experience on this website by contributing code directly to our GitLab repository.
            </p>
            <a href="">Go to gitlab</a>
          </div>
        </div>
        <h1 className={styles.improve_the_website}>Donate</h1>
        <h2 className={styles.improve_the_website_sub}>
          SFLC.in is a donor-supported organisation. Your contributions keep us
          going in fighting for civil liberties for citizens in the digital
          world.
        </h2>

        <div className={styles.donate_section}>
          <div className={styles.donate_col}>
            <h1>Donate by Bank Transfer</h1>
            <p>
              You can also send donations by transferring the amount directly to
              this bank account:<br></br>
              <b>A/C Name</b>: SFLCIN SOFTWARE FREEDOM LAW CENTER<br></br>
              <b>A/C Number</b>: 910010016412278<br></br> <b>Bank/Branch</b>:
              AXIS Bank, Shakti Nagar Branch, Delhi<br></br>
              <b>IFSC Code</b>: UTIB0000054
            </p>
          </div>
          <div className={styles.donate_col}>
            <h1>Donate Online</h1>
            <p>You can make digital donations using:</p>
            <ul>
              <li>Credit Cards </li>
              <li>Debit Cards and </li>
              <li>Net Banking</li>
            </ul>
            <p>for any amount. Click here to donate online.</p>
            <a href="https://store.sflc.in" className={styles.donate_btn}>Donate</a>
          </div>
          <div className={styles.donate_col}>
            <h1>Donate by Cheque</h1>
            <p>{`Donate By Cheque: Please draw your cheque in the name of 'Software
              Freedom Law Centre' and send it to the following address SFLC.in,
              K-9, Birbal Road, Second Floor, Jangpura Extension, New Delhi
              110014 (Tel: +91 11 43587126)`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
