import React, {useEffect, useRef, useState} from "react";
import { case_studies_details_data } from "../../temp/constant";
import styles from "./CaseStudiesDetail.module.scss";
import {SocialShareBtn, BlogTags, BreadCrumb} from "../../components/elements/Elements";
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getRquest } from "../../api";
import { useParams } from "react-router-dom";
import { slugify } from "../../components/case_studies/CaseStudies";
import Loader from "../../components/loader/Loader";
import { Helmet } from 'react-helmet-async';
import MetaTags from "../../components/meta_tags/MetaTags";



export default function CaseStudiesDetail() {

const [content, setContent] = useState(null);
const [previousPost, setPreviousPost] = useState(null);
const [nextPost, setNextPost] = useState(null);


const { postid } = useParams()

async function fetchAdjacentPosts(currentPostId) {
    // Fetch the previous post
    try {
        const res= await getRquest(`case-studies?filters[id][$lt]=${currentPostId}&sort=id:desc&pagination[limit]=1&populate=*`)
        setPreviousPost(res.data[0])
    } catch (error) {
        console.log(error)
    }

    //fetch the next post

      try {
        const res= await getRquest(`case-studies?filters[id][$gt]=${currentPostId}&sort=id:asc&pagination[limit]=1&populate=*`)
        setNextPost(res.data[0])
    } catch (error) {
        console.log(error)
    }
 
  }

useEffect(() => {
    fetchData();
    fetchAdjacentPosts(postid)
}, []);
const fetchData = async () => {
    try {
        const response = await getRquest('case-studies/'+postid+"?populate=*");
        setContent(response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

    const pre_data = case_studies_details_data;
    const next_data = case_studies_details_data;
    const data = case_studies_details_data;

    const color ={"Book Bans":"red","Restrictive Social Media Policies":"orange","Art Bans":"green","Website & App Bans":"cyan"}
    
    if (!content){
        return <div><Loader/></div>
    }

    const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"CASE STUDY","url":"/case_study"}}

    return (
        <div className="case_studies_outer">
             <Helmet> 
    {MetaTags(content.attributes.title,content.attributes.subtitle, content.attributes.featured_image.data != null ? ("https://ahveeeez.sflc.in"+content.attributes.featured_image.data.attributes.url) : ("/images/case_studies/1.jpeg"), window.location.href)}
      </Helmet>
   <BreadCrumb breadcrumb={breadcrumb} />
        <div className={styles.case_studies_details}>
            <div className={styles.container}>
                <img className={styles.image} src={content.attributes.featured_image.data != null ? ("https://ahveeeez.sflc.in"+content.attributes.featured_image.data.attributes.url) : ("/images/case_studies/1.jpeg")} alt={content.attributes.title} />
                <div className={styles.case_study_title_panel}>
                    CASE STUDY <SocialShareBtn/>
                </div>
                <div className={styles.blog_tags}>
                <BlogTags tag={content.attributes.tag} bg={color[content.attributes.tag]}/>
                </div>
                <div className={styles.heading}>{content.attributes.title}</div>
                <div className={styles.sub_heading}>{content.attributes.subtitle}</div>
                <div className={"body " + styles.description}>
                    <Markdown rehypePlugins={[rehypeRaw]}>{content.attributes.description}</Markdown>
                    
                </div>
                {content.attributes.related_link ? <div className={styles.relevant_links}>Relevant links
                <div>
                {
                 <a className="no_text_decoration" href={content.attributes.related_link} target="_blank" rel="noreferrer">{content.attributes.related_link}</a>
                }
                </div>
                </div>:""}
                
              
            </div>
            <div className={styles.nav_row}>
               {previousPost ?  <div className={styles.nav_container} >
                    <div className={styles.content} previous='true' onClick={()=>{window.location.href=`/${previousPost.id}/${slugify(previousPost.attributes.title)}`}}>
                        <div className={styles.caption}>&nbsp;previous casestudy</div>
                        <div className={styles.title}>{previousPost.attributes.title}</div>
                    </div>
                    <img src={"https://ahveeeez.sflc.in"+previousPost.attributes.featured_image.data.attributes.formats.small.url} alt={previousPost.attributes.title} />
                </div> : ""}
                {nextPost ? <div className={styles.nav_container}>
                    <div className={styles.content}  onClick={()=>{window.location.href=`/${nextPost.id}/${slugify(nextPost.attributes.title)}`}}>
                        <div className={styles.caption}>next casestudy&nbsp;</div>
                        <div className={styles.title}>{nextPost.attributes.title}</div>
                    </div>
                    <img src={"https://ahveeeez.sflc.in"+nextPost.attributes.featured_image.data.attributes.formats.small.url} alt={nextPost.attributes.title} />
                </div> : ""}
            </div>
        </div>
        </div>
    );
}