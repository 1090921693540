import InputDate from '../../components/report_a_ban/input_date/InputDate'
import InputRadio from '../../components/report_a_ban/input_radio/InputRadio'
import Styles from './BanForm.module.scss'

export const WebsiteBanForm = ({ data, setData, formRef }) => {
    return (
        <form className={Styles.form} ref={formRef}>
              <div className={Styles.question_heading}>You are reporting a website/ application/ social media ban</div>
            <div className={Styles.gap} />
            <InputRadio name="what_is_banned" title={"What are you unable to access? "} selected={data.what_is_banned}
                setSelected={(e) => setData({ ...data, what_is_banned: e })} options={[
                    "Websites",
                    "Applications",
                    "Social media handles"
                ]} />
            <label className={Styles.question} htmlFor="title">Name</label>
            <input className={Styles.input} type="text" name="name" id="title" />
            <label className={Styles.question+ " "+ Styles.not_required} htmlFor="genre">Genre of content hosted</label>
            <input className={Styles.input} type="text" name="genre" id="genre" />
            <label className={Styles.question + " "+ Styles.not_required} htmlFor="issued">Issuing authority</label>
            <input className={Styles.input} type="text" name="who_issued_the_order" id="issued" />
            {/* asked by gyan to remove <label className={Styles.question} htmlFor="quantity">How many were banned together in the order?</label>
            <input className={Styles.input} type="text" name="how_many_were_banned" id="quantity" /> */}
            <label className={Styles.question} htmlFor="reason">Reason of ban</label>
            <textarea className={Styles.textarea} placeholder='Answer in a phrase here' type="text" name="what_was_the_reason" id="reason" />
            <InputDate field_name={"Reported date of ban"} name='date_of_ban' />
            <label className={Styles.question} htmlFor="law">Legislation issued under </label>
            <input className={Styles.input} type="text" name="law_used" id="law" />
            <label className={Styles.question} htmlFor="description">Short description </label>
            <textarea className={Styles.textarea} placeholder='Description' type="text" name="description" id="description" />
            <div className={Styles.gap} />
            <InputRadio name="current_status" title={"Current status"} selected={data.current_status}
                setSelected={(e) => setData({ ...data, current_status: e })} options={[
                    "Blocked",
                    "Released",
                    "NA"
                ]} />
            <label className={Styles.question} htmlFor="source">Link to media report 
            </label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="link_to_the_source" id="source" />
        </form>
    )
}

export const ArtBanForm = ({ data, setData, formRef }) => {
    return (
        <form className={Styles.form} ref={formRef}>
              <div className={Styles.question_heading}>You are reporting an art ban </div>
            <div className={Styles.gap} />
            <InputRadio name="types_of_art" title={"Type"} selected={data.types_of_art}
                setSelected={(e) => setData({ ...data, types_of_art: e })} options={[
                    "Artist",
                    "Movie",
                    "Web Series",
                    "OTT Platform",
                    "Others"
                ]} />
            <label className={Styles.question} htmlFor="genre">Genre</label>
            <input className={Styles.input} type="text" name="genre" id="genre" />
            <label className={Styles.question} htmlFor="link">Link to image/ poster</label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="image_link" id="link" />
            <div className={Styles.gap} />
            <InputRadio name="reason_of_ban" title={"Reported reason of ban"} selected={data.reason_of_ban}
                setSelected={(e) => setData({ ...data, reason_of_ban: e })} options={[
                    "Religious",
                    "Political",
                    "Affecting pending litigation",
                    "Sexually expllicit",
                    "Others"
                ]} />
            <InputDate field_name={"Reported date of ban"} name={"date_of_ban"} />
            <label className={Styles.question} htmlFor="law">Legislation issued under </label>
            <input className={Styles.input} type="text" name="law_used" id="law" />
            <label className={Styles.question} htmlFor="description">Short description </label>
            <textarea className={Styles.textarea} placeholder='Description' type="text" name="description_of_ban" id="description" />
            <label className={Styles.question} htmlFor="issued">Issuing authority</label>
            <input className={Styles.input} type="text" name="who_issued_the_order" id="issued" />
            <label className={Styles.question} htmlFor="responsible">Initiating authority</label>
            <input className={Styles.input} type="text" name="who_was_responsible_for_initiating_the_ban" id="responsible" />
            <div className={Styles.gap} />
            <InputRadio name="current_status" title={"Current status"} selected={data.current_status}
                setSelected={(e) => setData({ ...data, current_status: e })} options={[
                    "Blocked",
                    "Released",
                    "NA"
                ]} />
            {/* <div className={Styles.gap} /> */}
            {/* <InputRadio name="edits_were_made" title={"Whether edits were made for re-release?"} selected={data.edits_were_made}
                setSelected={(e) => setData({ ...data, edits_were_made: e })} options={[
                    "Yes",
                    "No",
                    "NA"
                ]} /> */}
            <label className={Styles.question} htmlFor="source">Link to media report</label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="link_to_the_source" id="source" />
        </form>
    )
}

export const BookBanForm = ({ data, setData, formRef }) => {

    const setValue = (key, value) => {
        setData({ ...data, [key]: value })
    }

    return (
        <form className={Styles.form} ref={formRef} >
  <div className={Styles.question_heading}>You are reporting a book ban </div>
            <label className={Styles.question} htmlFor="title">Title</label>
            <input className={Styles.input} value={data.title} onChange={(e) => setValue("title", e.target.value)} type="text" name="title" id="title" />
            <label className={Styles.question} htmlFor="genre">Genre</label>
            <input className={Styles.input} type="text" name="genre" id="genre" />
            {/* <div className={Styles.gap} /> */}
            {/* <InputRadio name="are_there_multiple_authors" title={"Are there multiple authors?"} selected={data.are_there_multiple_authors}
                setSelected={(e) => setValue("are_there_multiple_authors", e)}
                options={[
                    "Yes",
                    "No",
                ]} /> */}
            <label className={Styles.question} htmlFor="author_name">Author name(s)</label>
            <input className={Styles.input} type="text" name="author_name" id="author_name" />
            <div className={Styles.gap} />
            <InputRadio name="Gender" title={"Gender"} selected={data.Gender}
                setSelected={(e) => setValue('Gender', e)}
                options={[
                    "Male",
                    "Female",
                    "Non-binary" 
                ]} />
            <label className={Styles.question} htmlFor="language_of_the_book">Language</label>
            <input className={Styles.input} placeholder='Enter Language' type="text" name="language_of_the_book" id="language_of_the_book" />
            <label className={Styles.question} htmlFor="link_to_book_cover">Link to media report</label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="link_to_book_cover" id="link_to_book_cover" />
            <div className={Styles.gap} />
            <InputRadio name="is_the_book_content_based_on_any_of_categories" title={"Category"}
                setSelected={(e) => setData({ ...data, is_the_book_content_based_on_any_of_categories: e })}
                selected={data.is_the_book_content_based_on_any_of_categories}
                options={[
                    "People based",
                    "Place based",
                    "Incident based",
                    "Includes iverse voices (i.e. marginalised perspectives)",
                    "Others",
                ]} />
            {/* <label className={Styles.question} htmlFor="who_is_the_person">Who is the person?/ Which Place?/What Incident? :</label>
            <input className={Styles.input} type="text" name="who_is_the_person" id="who_is_the_person" /> */}
            {/* <div className={Styles.question_heading}>Questions about the ban</div> */}
            <div className={Styles.gap} />
            <InputRadio name="what_was_the_reason" title={"Reason"}
                selected={data.what_was_the_reason}
                setSelected={(e) => setData({ ...data, what_was_the_reason: e })}
                options={[
                    "Religious",
                    "Political",
                    "Defamation",
                    "Hate speech",
                    "Factual errors",
                    "Misleading",
                    "Sexually explicit",
                    "After partition",
                    "Racist",
                    "Others",
                ]} />
            <InputDate field_name={"Reported date of ban" } name={'date_of_ban'} />
            <label className={Styles.question} htmlFor="law_used_to_ban">Legislation issued under </label>
            <input className={Styles.input} type="text" name="law_used_to_ban" id="law_used_to_ban" />
            <label className={Styles.question} htmlFor="description_of_ban">Short description </label>
            <textarea className={Styles.textarea} placeholder='Description' type="text" name="description_of_ban" id="description_of_ban" />
            <label className={Styles.question} htmlFor="who_was_responsible_for_initiating_the_ban">Initiating authority</label>
            <input className={Styles.input} type="text" name="who_was_responsible_for_initiating_the_ban" id="who_was_responsible_for_initiating_the_ban" />
            <label className={Styles.question} htmlFor="link_to_the_source_of_information">Link to media report</label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="link_to_the_source_of_information" id="link_to_the_source_of_information" />
        </form>
    )
}


export const SocialMediaBanForm = ({ data, setData, formRef }) => {
    return (
        <form className={Styles.form} ref={formRef}>
            <div className={Styles.question_heading}>You are reporting a restrictive social media policy </div>

            <label className={Styles.question} htmlFor="organisation">Name of organisation</label>
            <input className={Styles.input} type="text" name="org_name" id="organisation" />
            {/* <label className={Styles.question} htmlFor="kind">Kind of organisation</label>
            <input className={Styles.input} type="text" name="kind_of_org" id="kind" /> */}
            <label className={Styles.question} htmlFor="who">What is prohibited in the policy?</label>
            <input className={Styles.input} type="text" name="what_is_banned" id="who" />
            {/* <label className={Styles.question} htmlFor="about">What was the post about for which they were fired?</label>
            <input className={Styles.input} type="text" name="what_was_the_post" id="about" /> */}
            {/* <InputDate field_name={"Reported date of ban"} /> */}
            <label className={Styles.question} htmlFor="description">Short description </label>
            <textarea className={Styles.textarea} placeholder='Description' type="text" name="description" id="description" />
            <label className={Styles.question+ " "+ Styles.not_required} htmlFor="source">Link to media report</label>
            <input className={Styles.link} placeholder='Web Url' type="text" name="link_to_the_source_of_information" id="source" />
        </form>
    )
}