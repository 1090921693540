import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import Loader from '../../loader/Loader';

const SankeyChart = ({classN}) => {
  const [sankeyData, setSankeyData] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://ahveeeez.sflc.in/api/arts?populate=reasons,types')
      .then(response => {
        const data = response.data.data;

        // Process the data to count connections between types and reasons
        const connections = {};
        console.log(connections," connections"
        )
        data.forEach(item => {
          const types = item.attributes.types.data;
          console.log(types," types")
          const reasons = item.attributes.reasons.data;
          console.log(reasons," reasons")

          types.forEach(type => {
            const typeName = type.attributes.type;
            reasons.slice().reverse().forEach(initiator => { // Reverse the order of reasons
              const initiatorName = initiator.attributes.name;
              const key = `${typeName}-${initiatorName}`;
              connections[key] = (connections[key] || 0) + 1;
            });
          });
        });
        console.log(connections," connections")
        // Transform the connections object into an array for the Sankey chart
        const sankeyData = Object.entries(connections).map(([key, weight]) => {
          const [from, to] = key.split('-');
          return [from, to, weight];
        });

        // Add headers for Google Charts
        const sankeyDataFormatted = [['From', 'To', 'Instances'], ...sankeyData];

        setSankeyData(sankeyDataFormatted);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const options = {
    sankey: {
      node: {
        labelPadding:5 ,
        label:{fontName: 'Epilogue',fontSize:14},
        width:6,
        colors: ["#78A570","#F29454","#FFD450","#2085D6"],
        nodePadding:25
      },
      link: {
        colorMode: 'target',
        colors: {fill:["#78A57065","#F2945465","#FFD45065","#2085D665"]},
      },
    },
  };

  return (
    <div className={classN} style={{ width: '100%', height: '400px' }}>
      {sankeyData ? (
        <Chart
          chartType="Sankey"
          width="100%"
          height="400px"
          data={sankeyData}
          options={options}
        />
      ) : (
      
        <Loader/>
      )}
    </div>
  );
};

export default SankeyChart;
