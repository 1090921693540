import React from "react";
import styles from "./LightsCameraBanned.module.scss";
import {
  StoriesPageHeader,
  StoryBody,
  StoryDownloadBtn,
} from "../../components/elements/stories_page/StoriesPage";
import LineChart2 from "../../components/elements/charts/LineChart2";
import SankeyChart from "../../components/elements/charts/SankyChart";
import HorizontalChart from "../../components/elements/charts/HorizontalChart";
import SankyGoogle from "../../components/elements/charts/SankyGoogle";
import StackedBarChart from "../../components/elements/charts/HorizontalChart";
import StackedBarCharts from "../../components/elements/charts/StackedBarChart";
import LineChart from "../../components/elements/charts/LineChart";
import SankeyChart3 from "../../components/elements/charts/SankyChartArt2";
import QuizPage from "../quiz/QuizPage";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";

function LightsCameraBanned({setReport}) {
  const breadcrumb = {"page1":{"name":"HOME","url":"/"},"page2":{"name":"STORIES","url":"/stories"}}
  return (
    <div className={styles.container}>
                             <Helmet>   
    {MetaTags("Lights, Camera, Banned","Illuminating India's artistic censorship landscape across film, television, and online platforms.", "./images/featured_stories/2.png", window.location.href)}
      </Helmet>
      <StoriesPageHeader breadcrumb={breadcrumb} setReport={setReport}
      tag_bg={"green"}
        tag={"Art Bans"}
        title={"Lights, Camera, Banned"}
        sub_heading={
          "Illuminating India's artistic censorship landscape across film, television, and online platforms."
        }
        img={"./images/featured_stories/2.png"}
      />
      <StoryBody
        desc={
          "The Indian film industry is primarily governed by The Cinematograph Act of 1952, which monitors and examines the films exhibited to the Indian society and ensures that they should be within tolerance of morality and temperament as per Articles 19(1) and 19(2) of the Constitution of India. The Act has undergone several amendments and revisions over the years to adapt to the changing dynamics of the film industry and society. The latest amendment was enacted in in July of 2023, by which certification categories were added, section 6(1) was struck down and piracy concerns were more thoroughly addressed."
        }
      />
      <div>
        <div className="charts_container no_scroll" id="chart-container">
          {/* <LineChart2/> */}
          <LineChart classN={styles.line_chart_arts} />
        </div>
        <StoryBody
          desc={
            "The 1952 Act also established the CBFC (Central Board of Film Certification), also referred to as the Censor Board, as the primary determining authority to uphold suitable content in movies. It also gives the board power to require the deletion of any objectionable material before granting certification, a provision that many criticize as violative of freedom of speech and expression"
          }
        />
        <div className="slim_chart_container">
          <SankeyChart classN={styles.sanky_chart} />
        </div>

        <StoryBody
          desc={
            "The Government set up a three-level grievance redressal mechanism under the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021. The redressal system gives citizens a forum for their grievances with pre-existing content on OTT platforms."
          }
        />
        <div className="slim_chart_container">
          <SankeyChart3 classN={styles.sanky_chart} />
        </div>
        <StoryBody
          desc={
            "The draft Broadcasting Bill, 2023 introduces a new system of self-regulation through content evaluation committees. OTT platforms would then only be able to broadcast programs that are pre-approved by these committees. Under this bill, transmissions of channels can again be prohibited on various grounds (protecting the sovereignty, integrity, or security of India, friendly relations with other nations, public order, decency, and morality). "
          }
        />

        <div className="charts_container" id="chart-container">
          <div className="charts_container_wrapper">
            <HorizontalChart />
          </div>
        </div>

        <div className="slim_container">
          <StoryDownloadBtn setReport={setReport} ban={"Art"} />
        </div>
        <div className={styles.quiz_section}>
          <QuizPage/>
        </div>
      </div>
    </div>
  );
}

export default LightsCameraBanned;
