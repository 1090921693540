import React from 'react'

function MetaTags(title, desc, image, url) {
  return (
   <>
       <meta
      name={title}
      content={desc}
    /> 
       {/* open graph meta tags */}
       <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="Free Speech Tracker by SFLC.IN" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />

        {/* twitter cards meta tag */}

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={desc}/>
        <meta name="twitter:site" content="@sflcin"/>
        <meta name="twitter:image" content={image}/>
        <meta name="twitter:creator" content="@sflcin"/>
   </>
  )
}

export default MetaTags