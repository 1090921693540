import { useEffect, useState } from 'react';
import { faqs_data } from '../../temp/constant';
import styles from './FAQs.module.scss';
import { getRquest } from '../../api';
import axios from 'axios';
import Loader from '../loader/Loader';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export default function FAQs() {

    const data = faqs_data;
    const [expanded, setExpanded] = useState(-1);
    const [response, setResponse] = useState(null)

   useEffect(()=>{
    
    fetchData();
   },[]);
   const fetchData = async () =>{
    try {
        const res = await getRquest("faqs")
        setResponse(res)
    }
    catch{

    }
}

    return (
        <>
            <div className={styles.heading}>FAQs</div>
            <div className={styles.sub_heading}>This section addresses any questions you may have about the Free Speech Tracker, whether you're a citizen, researcher, activist, or policymaker. Let's dive into understanding FST step by step.</div>
            {response ? response.data.map((item) => <Card key={item.id} data={item} isExpanded={expanded === item.id} setExpanded={setExpanded} />): <Loader/>}
        </>
    )
}

const Card = ({ data, isExpanded, setExpanded }) => {
    const handleClick = () => {
        if (isExpanded) {
            setExpanded(-1);
        } else {
            setExpanded(data.id);
        }
    }
    return (
        <div onClick={handleClick} className={styles.card + ' ' + (isExpanded ? styles.expanded : '')}>
            <div className={styles.header}>
                <div className={styles.title}>{data.attributes.question}</div>
                <div className={styles.collapse}><Collapse /></div>
            </div>
            <div className={styles.callapsed_content}>
                <div className={styles.description}>
                    <Markdown rehypePlugins={[rehypeRaw]}>{data.attributes.answer}</Markdown>
                    </div>
            </div>
        </div>
    )
}

const Collapse = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7.5625V6.25H14V7.5625H0Z" fill="black" />
            <path d="M7.75 0H6.25V14H7.75V0Z" className={styles.rotate} fill="black" />
        </svg>
    )
}