import styles from './App.module.scss'
import Router from './Router';


function App() {
  return (
    <div className={"web_1368 " + styles.App}>
      <div className={styles.container}>
        <Router />
  
      </div>
    </div>
  );
}

export default App;
