import { useState,useEffect } from 'react';
import CaseStudies from '../../components/case_studies/CaseStudies';
import Featured from '../../components/featured/Featured';
import FeaturedStories from '../../components/featured_stories/FeaturedStories';
import Latest from '../../components/latest/Latest';
import { Helmet } from 'react-helmet-async';
import MetaTags from '../../components/meta_tags/MetaTags';
import styles from './Home.module.scss';
import Confetti from 'react-confetti';

export default function Home({setReport }) {

    const [fadeOut, setFadeOut] = useState(false);
  
    return (
        <div className={styles.home}>
          
                                   <Helmet>   
    {MetaTags("Free Speech tracker by SFLC.in","The Free Speech Tracker website is a tool for monitoring and documenting instances of free speech violations across India. In an era where freedom of expression is increasingly under scrutiny and faces various challenges, this platform serves as a centralized repository of information for citizens, researchers, activists, and policymakers to defend our free speech.", "/images/featured_image.png", window.location.href)}
      </Helmet>
            <Featured image={"/images/featured_image.webp"} showReport={() => setReport(true)} />
            <div className={styles.content}>
                <div className={styles.featured_stories}><FeaturedStories /></div>
                <div className={styles.latest}><Latest /></div>
            </div>
            <CaseStudies />

        </div>
    );
}