import { useState,useEffect } from 'react';
import { case_studies_data } from '../../temp/constant';
import styles from './CaseStudies.module.scss';
import { useNavigate } from 'react-router-dom';
import { getRquest } from '../../api';
import { BlogTags } from '../elements/Elements';
import Loader from '../loader/Loader';


const data = case_studies_data;
export default function CaseStudies() {

    const [apiData, setApiData] = useState([]); 
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await getRquest('case-studies?populate=*&pagination[pageSize]=3');
            setApiData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const onNext = () => {
        if (selected < apiData.length - 1) {
            setSelected(selected + 1);
        }
    }

    const onPrev = () => {
        if (selected > 0) {
            setSelected(selected - 1);
        }
    }


    return (
        <div>
            {apiData.length > 0 ? (
                <Card
                    data={apiData[selected]}
                    next={onNext}
                    prev={onPrev}
                    selected={selected}
                    totalCount={apiData.length}
                />
            ) : (
                <Loader/> // Optional: Add a loading state
            )}
        </div>
    );
 
}

export function slugify(text) {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-');
};

const Card = ({ data, next, prev, selected, totalCount }) => {
    const color ={"Book Bans":"red","Restrictive Social Media Policies":"orange","Art Bans":"green","Website & App Bans":"cyan"}
   
    return (
        <div  className={'card ' + styles.case_studies}>
            <div className={styles.content}>
                <div className={styles.heading}>Case Studies</div>
                <div className={styles.caption}>
                    <BlogTags tag={data.attributes.tag} bg={color[data.attributes.tag]}/>
                </div>
                <div className={'title ' + styles.title}><a href={`/${data.id}/${slugify(data.attributes.title)}`}>{data.attributes.title}</a></div>
                <div className={'subtitle '+styles.subtitle}><a href={`/${data.id}/${slugify(data.attributes.title)}`}>{data.attributes.subtitle}</a></div>
                <div className={styles.gap} />
                <div className={styles.nav}>
                    <img className={styles.left_nav} src={'./images/left_arrow.png'} alt='prev' onClick={prev} />
                    <img className={styles.right_nav} src={'./images/right_arrow.png'} alt='next' onClick={next} />
                </div>
            </div>
            <div className={styles.center}>
                <div className={styles.view_all} onClick={()=>{window.location.href = '/case_study';}} >View all</div>
                <div className={styles.selected}> {selected + 1}/{totalCount}</div>
            </div>
            <img style={{cursor:'pointer'}} onClick={()=>{window.location.href=`/${data.id}/${slugify(data.attributes.title)}`}} className={styles.image} src={data.attributes.featured_image.data != null ? "https://ahveeeez.sflc.in"+data.attributes.featured_image.data.attributes.url: "/images/case_studies/1.jpeg"} alt={data.attributes.title + " Image"} />
        </div>
    );
}