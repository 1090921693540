import React from 'react';
import loaderGif from './loader.gif';
import styles from "./loader.module.scss";

function Loader({status, withf}) {
  return (
    <div className={styles.loader}>
        <img src={loaderGif} style={{width: withf || "100px" }} alt="loader" />
        <p>{status || "Loading"}</p>
    </div>
  )
}

export default Loader