import React,{useState,useEffect} from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Stories from './pages/stories/Stories';
import CaseStudiesDetail from './pages/case_studies_detail/CaseStudiesDetail';
import CaseStudy from './pages/case_study/CaseStudy';
import AboutUs from './pages/about_us/AboutUs';
import ReadingTheFinePrint from './pages/reading_the_fine_print/ReadingTheFinePrint';
import QuizPage from './pages/quiz/QuizPage';
import LightsCameraBanned from './pages/lights_camera_banned/LightsCameraBanned';
import ToReadOrNot from './pages/to_read_or_not/ToReadOrNot';
import WebsiteNotFound from './pages/404_website_not_found/WebsiteNotFound';
import ReportABan from './pages/report_a_ban/ReportABan';
import ContactUs from './pages/contact_us/ContactUs';
import axios from 'axios';
import ComingSoon from './pages/coming_soon/ComingSoon';
import Launch from './pages/launch/Launch';
import Loader from './components/loader/Loader';
function Router() { 
    const [report, setReport] = useState(false);

    const browserRouterVar = createBrowserRouter([
        {
            path: '*',
            element: <Navigate to="/" replace />
        },
        {
            path: '/',
            element: (
                <>
                    <Header />
                    <Outlet />
                    <Footer />
                </>
            ),
            children: [
                { index: true, element: <Home report={report} setReport={setReport} /> },
                { path: 'stories', element: <Stories /> },
                { path: 'case_study', element: <CaseStudy /> },
                { path: ':postid/:title', element: <CaseStudiesDetail /> },
                { path: 'about_us', element: <AboutUs /> },
                { path: 'reading_the_fine_print', element: <ReadingTheFinePrint setReport={() => setReport(true)} /> },
                { path: 'lights_camera_banned', element: <LightsCameraBanned setReport={() => setReport(true)} /> },
                { path: 'quiz_page', element: <QuizPage /> },
                { path: 'contact-us', element: <ContactUs /> },
                { path: 'to-read-or-not', element: <ToReadOrNot setReport={() => setReport(true)} /> },
                { path: '404-website-not-found', element: <WebsiteNotFound setReport={() => setReport(true)} /> }
            ]
        }
    ]);



    return (
        <>
            <RouterProvider router={browserRouterVar} />
            {report && <ReportABan close={() => setReport(false)} />}
        </>
    );
}

export default Router;
