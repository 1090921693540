import React from 'react';

export function CopyIcon({yourClass}){
    return(<>
    <svg className={yourClass || 'copyIcon '} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  d="M4.9248 11.5007C4.55814 11.5007 4.24425 11.3701 3.98314 11.109C3.72203 10.8479 3.59147 10.534 3.59147 10.1673V2.16732C3.59147 1.80065 3.72203 1.48676 3.98314 1.22565C4.24425 0.96454 4.55814 0.833984 4.9248 0.833984H10.9248C11.2915 0.833984 11.6054 0.96454 11.8665 1.22565C12.1276 1.48676 12.2581 1.80065 12.2581 2.16732V10.1673C12.2581 10.534 12.1276 10.8479 11.8665 11.109C11.6054 11.3701 11.2915 11.5007 10.9248 11.5007H4.9248ZM4.9248 10.1673H10.9248V2.16732H4.9248V10.1673ZM2.25814 14.1673C1.89147 14.1673 1.57758 14.0368 1.31647 13.7757C1.05536 13.5145 0.924805 13.2007 0.924805 12.834V3.50065H2.25814V12.834H9.59147V14.1673H2.25814Z"/>
</svg>
    </>)
}

export function LinkIcon({yourClass}){
    return(<>
    <svg className={yourClass || 'link_icon '} viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.41602 5.91927H3.08268C2.27574 5.91927 1.58789 5.6349 1.01914 5.06615C0.450391 4.4974 0.166016 3.80955 0.166016 3.0026C0.166016 2.19566 0.450391 1.50781 1.01914 0.939063C1.58789 0.370312 2.27574 0.0859375 3.08268 0.0859375H5.41602V1.2526H3.08268C2.59657 1.2526 2.18338 1.42274 1.8431 1.76302C1.50282 2.1033 1.33268 2.51649 1.33268 3.0026C1.33268 3.48872 1.50282 3.90191 1.8431 4.24219C2.18338 4.58247 2.59657 4.7526 3.08268 4.7526H5.41602V5.91927ZM3.66602 3.58594V2.41927H8.33268V3.58594H3.66602ZM6.58268 5.91927V4.7526H8.91602C9.40213 4.7526 9.81532 4.58247 10.1556 4.24219C10.4959 3.90191 10.666 3.48872 10.666 3.0026C10.666 2.51649 10.4959 2.1033 10.1556 1.76302C9.81532 1.42274 9.40213 1.2526 8.91602 1.2526H6.58268V0.0859375H8.91602C9.72296 0.0859375 10.4108 0.370312 10.9796 0.939063C11.5483 1.50781 11.8327 2.19566 11.8327 3.0026C11.8327 3.80955 11.5483 4.4974 10.9796 5.06615C10.4108 5.6349 9.72296 5.91927 8.91602 5.91927H6.58268Z" />
</svg>
    </>)
}

