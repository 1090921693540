import styles from './Featured.module.scss';

export default function Featured({ image, showReport }) {
    return (
        <div className={'article ' + styles.featured}>
            <div className={styles.featured_image}>
                <img src={image} alt="featured" className={styles.image} />

                <small>Credit - <a href="https://asiatimes.com/2019/08/the-freedom-that-never-was/">Photo: AFP / Sam Panthaky</a></small>

            </div>
            <div className={styles.content}>
                <div className={styles.title}><span>Defending</span> <span>Free</span> <span>Speech</span> <span>through</span> <span>tracking</span> <span>Censorship</span></div>
                <div className={styles.description}>
                    Explore bans on <span className={styles.books} onClick={()=>{window.location.href="/to-read-or-not"}}>Books</span>, <span className={styles.art} onClick={()=>{window.location.href="/lights_camera_banned"}}>Art</span>, <span className={styles.website} onClick={()=>{window.location.href="/404-website-not-found"}}>Website</span> & <span className={styles.social_media} onClick={()=>{window.location.href="/reading_the_fine_print"}}>Social Media Policies.</span>
                </div>
                <button className={styles.button} onClick={showReport}>Report a ban</button>
            </div>
        </div>
    );
}