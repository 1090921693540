import { useEffect, useRef, useState } from 'react';
import Styles from './ReportABan.module.scss';
import InputRadio from '../../components/report_a_ban/input_radio/InputRadio';
import { ArtBanForm, BookBanForm, SocialMediaBanForm, WebsiteBanForm } from './BanForm';
import { postBan } from '../../api';

const FirstOptions = [
    'Website ban',
    'Art ban',
    'Book ban',
    'Restrictive social media policy'
];

export default function ReportABan({close}) {
    const [page, setPage] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [data, setData] = useState({});
    const formRef = useRef(null);
    const [submitted,setSubmitted] = useState(false);

    const onNext = async () => {
        if (page === 0) {
            setPage(1);
            return;
        } else {
            const formData = new FormData(formRef.current);
            const objectFormData = {};
            formData.forEach((value, key) => (objectFormData[key] = value ));
            console.log(objectFormData,"obsj")
            const jsonFormData = JSON.stringify({"data":objectFormData});
            console.log(jsonFormData);
            let endpoint = '';
            switch (data.type) {
                case FirstOptions[0]: endpoint = 'report-website-bans'; break;
                case FirstOptions[1]: endpoint = 'report-art-bans'; break;
                case FirstOptions[2]: endpoint = 'report-book-bans'; break;
                case FirstOptions[3]: endpoint = 'report-social-bans'; break;
                default:
                    console.log("Something went wrong", data.type);
                    break;
            }
           await postBan(jsonFormData, endpoint);
            // TODO change success message
            alert("Report Submitted");
            setPage(0);
            setCompleted(false);
            setData({});
            // close();
            setSubmitted(true);
        }
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.close_container} onClick={close}></div>
           <div className={Styles.inner_container}>
           <div className={Styles.header}>
                <div className={Styles.heading}>Report a ban</div>
                <img className={Styles.close} src='./images/close.png' alt='close' onClick={close} />
            </div>
            {page == 0 ? "":<div className={Styles.go_back} onClick={()=>{setPage(0)}}>&#x2190; Go back </div>}
            {submitted ? (
          <div className={Styles.response_message}>
          <div className={Styles.success_message}>Your response has been recorded.</div>
          <button className={Styles.button} onClick={()=>{setSubmitted(false)}}>
            Report another ban?
            </button>
          </div>
          
        ) : (
          <>
            {page === 0 ? (
              <ReportType data={data} setData={setData} setCompleted={setCompleted} />
            ) : (
              <ReportForm data={data} setData={setData} formRef={formRef} />
            )}
            <button className={Styles.button} disabled={!completed} onClick={onNext}>
              {page === 0 ? 'Next' : 'Submit'}
            </button>
          </>
        )}
           </div>
        </div>
    )
}

const ReportType = ({ data, setData, setCompleted }) => {

    const onSelect = (value) => {
        setData({ ...data, "type": value })
    }

    useEffect(() => {
        if (data.type) {
            setCompleted(true);
        }
    }, [data])

    return (
        <InputRadio setSelected={onSelect} title="What are you reporting?" selected={data.type} options={FirstOptions} />
    )
}

const ReportForm = ({ data, setData, formRef }) => {
    switch (data.type) {
        case FirstOptions[0]: return <WebsiteBanForm data={data} setData={setData} formRef={formRef} />
        case FirstOptions[1]: return <ArtBanForm data={data} setData={setData} formRef={formRef} />
        case FirstOptions[2]: return <BookBanForm data={data} setData={setData} formRef={formRef} />
        case FirstOptions[3]: return <SocialMediaBanForm data={data} setData={setData} formRef={formRef} />
        default:
            break;
    }
    return <>Item Not added</>
}
